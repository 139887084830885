
import { View, Text,Image, useWindowDimensions } from 'react-native';

import { styles } from './styles';
import { useState } from 'react';


import MonthButton from './MonthButton';

import Agosto01 from '../../../assets/images/agosto01.jpeg'
import Agosto02 from '../../../assets/images/agosto02.jpeg'
import Agosto03 from '../../../assets/images/agosto03.jpeg'
import Agosto04 from '../../../assets/images/agosto04.jpeg'
import Agosto05 from '../../../assets/images/agosto05.jpeg'
import Agosto06 from '../../../assets/images/agosto06.jpeg'
import Agosto07 from '../../../assets/images/agosto07.jpeg'
import Agosto08 from '../../../assets/images/agosto08.jpeg'

import Setembro01 from '../../../assets/images/setembro01.png'
import Setembro02 from '../../../assets/images/setembro02.png'

import Outubro00 from '../../../assets/images/outubro00.png'
import Outubro01 from '../../../assets/images/outubro01.png'
import Outubro02 from '../../../assets/images/outubro02.png'
import Outubro03 from '../../../assets/images/outubro03.png'
import Outubro04 from '../../../assets/images/outubro04.png'
import Outubro05 from '../../../assets/images/outubro05.png'

//FOTOS NOVEMBRO
import Novembro01 from '../../../assets/images/novembro/imageNov01.jpeg'
import Novembro02 from '../../../assets/images/novembro/imageNov02.jpeg'
import Novembro03 from '../../../assets/images/novembro/imageNov03.jpeg'
import Novembro04 from '../../../assets/images/novembro/imageNov04.jpeg'
import Novembro05 from '../../../assets/images/novembro/imageNov05.jpeg'
import Novembro06 from '../../../assets/images/novembro/imageNov06.jpeg'
import Novembro07 from '../../../assets/images/novembro/imageNov07.jpeg'
import Novembro08 from '../../../assets/images/novembro/imageNov08.jpeg'
import Novembro09 from '../../../assets/images/novembro/imageNov09.jpeg'

//FOTOS JANEIRO
import Janeiro01 from '../../../assets/images/janeiro/imageJan01.jpeg'
import Janeiro02 from '../../../assets/images/janeiro/imageJan02.jpeg'
import Janeiro03 from '../../../assets/images/janeiro/imageJan03.jpeg'
import Janeiro04 from '../../../assets/images/janeiro/imageJan04.jpeg'
import Janeiro05 from '../../../assets/images/janeiro/imageJan05.jpeg'
import Janeiro06 from '../../../assets/images/janeiro/imageJan06.jpeg'

//FOTOS FEVEREIRO
import Fevereiro01 from '../../../assets/images/fevereiro/imageFev01.jpeg'
import Fevereiro02 from '../../../assets/images/fevereiro/imageFev02.jpeg'
import Fevereiro03 from '../../../assets/images/fevereiro/imageFev03.jpeg'
import Fevereiro04 from '../../../assets/images/fevereiro/imageFev04.jpeg'
import Fevereiro05 from '../../../assets/images/fevereiro/imageFev05.jpeg'
import Fevereiro06 from '../../../assets/images/fevereiro/imageFev06.jpeg'
import Fevereiro07 from '../../../assets/images/fevereiro/imageFev07.jpeg'
import Fevereiro08 from '../../../assets/images/fevereiro/imageFev08.jpeg'


import { usePositions } from '../../../hooks/positions';
import { useDimensions } from '../../../hooks/dimensions';


const fotosAgosto = [
  Agosto01,
  Agosto02,
  Agosto03,
  Agosto04,
  Agosto05,
  Agosto06,
  Agosto07,
  Agosto08
]
const fotosSetembro = [
  Setembro01,
  Setembro02,
]
const fotosOutubro = [
  Outubro00,
  Outubro01,
  Outubro02,
  Outubro03,
  Outubro04,
  Outubro05,
]
const fotosNovembro = [
  Novembro01,
  Novembro02,
  Novembro03,
  Novembro04,
  Novembro05,
  Novembro06,
  Novembro07,
  Novembro08,
  Novembro09,
]

const fotosJaneiro = [
  Janeiro01,
  Janeiro02,
  Janeiro03,
  Janeiro04,
  Janeiro05,
  Janeiro06
]

const fotosFevereiro = [
  Fevereiro01,
  Fevereiro02,
  Fevereiro03,
  Fevereiro04,
  Fevereiro05,
  Fevereiro06,
  Fevereiro07,
  Fevereiro08
]

export default function Progress() {

  const [selectedMonth, setSelectedMonth] = useState('agosto')
  const {setPositions} = usePositions()
  const {width} = useWindowDimensions()

  const {orientation} = useDimensions()

  const padding = width * 0.24
  const CARD_WIDTH = 240

  const GAP = (width - (padding + CARD_WIDTH * 4)) / 3



  return (
   <View 
   onLayout={(e)=>{setPositions((prevState)=> {return {...prevState,progress: e.nativeEvent.layout.y}})}}
   style={styles.container}>
      <Text style={[styles.textTitle,{
        fontSize: orientation === 'web' ? 80 : 30
      }]}>Andamento da obra</Text>
    
    <View style={styles.viewButtons}>
      <MonthButton onPress={()=>{setSelectedMonth('agosto')}} text={'AGOSTO 2024'}/>
      <MonthButton onPress={()=>{setSelectedMonth('setembro')}} text={'SETEMBRO 2024'}/>
      <MonthButton onPress={()=>{setSelectedMonth('outubro')}} text={'OUTUBRO 2024'}/>
      <MonthButton onPress={()=>{setSelectedMonth('novembro')}} text={'NOVEMBRO 2024'}/>
      <MonthButton onPress={()=>{setSelectedMonth('janeiro')}} text={'JANEIRO 2025'}/>
      <MonthButton onPress={()=>{setSelectedMonth('fevereiro')}} text={'FEVEREIRO 2025'}/>

    </View>


    <View style={[orientation === 'web' ? styles.viewFotos : styles.viewFotosMobile ,{gap: GAP - 1}]}>
      {selectedMonth === 'agosto' &&    <> {fotosAgosto.map((item,index)=>{
        return(
          <Image style={orientation === 'web' ? styles.image: {height: width - 10, width: width - 10, marginBottom: 5,}} key={index} source={item}/>
        )
      })}</>}

      {selectedMonth === 'setembro' &&  <> {fotosSetembro.map((item,index)=>{
        return(
          <Image style={orientation === 'web' ? styles.image: {height: width - 10, width: width - 10, marginBottom: 5,}} key={index} source={item}/>
        )
      })}</>}

        {selectedMonth === 'outubro' &&  <> {fotosOutubro.map((item,index)=>{
        return(
          <Image style={orientation === 'web' ? styles.image: {height: width - 10, width: width - 10, marginBottom: 5,}} key={index} source={item}/>
        )
      })}</>}

      {selectedMonth === 'novembro' &&  <> {fotosNovembro.map((item,index)=>{
        return(
          <Image style={orientation === 'web' ? styles.image: {height: width - 10, width: width - 10, marginBottom: 5,}} key={index} source={item}/>
        )
      })}</>}

      {selectedMonth === 'janeiro' &&  <> {fotosJaneiro.map((item,index)=>{
        return(
          <Image style={orientation === 'web' ? styles.image: {height: width - 10, width: width - 10, marginBottom: 5,}} key={index} source={item}/>
        )
      })}</>}

      {selectedMonth === 'fevereiro' &&  <> {fotosFevereiro.map((item,index)=>{
        return(
          <Image style={orientation === 'web' ? styles.image: {height: width - 10, width: width - 10, marginBottom: 5,}} key={index} source={item}/>
        )
      })}</>}
    </View>
   
   </View>
      
  );
}

