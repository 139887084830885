
import { StyleSheet } from 'react-native';


export const styles = StyleSheet.create({
    container: {
        marginTop: 100,
        paddingHorizontal: '12%',
        width: '100%',
    },

    textTitle: {
        fontSize: 64,
        marginBottom: 30,
        fontFamily: 'Roboto-Bold',
        color: '#333333'
    },

    viewButtons: {
        flexDirection: 'row',
        gap: 30,
        marginBottom: 50,
    },

    viewFotos: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },

    viewFotosMobile: {
        alignItems: 'center', justifyContent: 'center',
    },

    image: {
        height: 240,
        width: 240,
        resizeMode: 'cover',
    },

    imageMobile: {
        height: 200,
        width: 200,
        resizeMode: 'cover',
    }
  

  });
  