
import { Text, View, TouchableOpacity, Image } from 'react-native';

import { styles } from './styles';
import { useCallback, useState } from 'react';

import Image1 from '../../../assets/images/image1.png'
import Image2 from '../../../assets/images/image2.png'
import Image3 from '../../../assets/images/image3.png'

import Image4 from '../../../assets/images/image4.png'
import Image5 from '../../../assets/images/image5.png'
import Image6 from '../../../assets/images/image6.png'

import ArrowLeft from '../../../assets/svg/ArrowLeft';
import { usePositions } from '../../../hooks/positions';
import { useDimensions } from '../../../hooks/dimensions';
export default function Project() {

  const [textSize, setTextSize] = useState(0)

  const {setPositions} = usePositions()

  const {orientation} = useDimensions()




  const imagesWeb = useCallback((img1,img2,img3)=>{
    return (
      <View style={[styles.viewImages]}>
  
        <Image source={img1} style={styles.image}/>
      
        <Image source={img2}  style={styles.image}/>
        <Image source={img3}  style={styles.image}/>
  
    </View>
    )
  },[])


  const imagesMobile = useCallback((img1,img2,img3)=>{
    return (
      <View style={styles.viewImagesMobile}>
      <View>
        <Image source={img1} style={styles.imageMobile}/>
      </View>
      <View>
        <Image source={img2}  style={styles.imageMobile }/>
        <Image source={img3}  style={styles.imageMobile}/>
      </View>
    </View>
    )
  },[])

 

  return (
    <View 
    onLayout={(e)=>{setPositions((prevState)=> {return {...prevState,project: e.nativeEvent.layout.y}})}}
    style={styles.container}>

      <View style={{flexDirection: orientation === 'web' ? 'row' : 'column', justifyContent: 'space-between', alignItems: 'center' }}>

      <View style={{alignItems: 'flex-start', width: orientation === 'web' ? textSize + 1 : '100%', alignItems: 'center'}}>
          <Text 
          onLayout={(e)=>{
            setTextSize(e.nativeEvent.layout.width)
          }} 
          style={[styles.textTitle,{
            fontSize: orientation === 'web' ? 80 : 40
          }]}>O projeto</Text>
        
          <View style={styles.underlineTitle}/>
      </View>

      <TouchableOpacity onPress={()=>{
        window.open('https://firebasestorage.googleapis.com/v0/b/sitequattro-2c26e.appspot.com/o/pdfPinda%2FO%20PROJETO.pdf?alt=media&token=b1f30836-41c3-4897-b51e-3c78f741c9c9')
      }} style={[styles.buttonMore,{
        marginBottom: 30,
      }]}>
          <Text style={styles.textButton}>
            SAIBA MAIS
          </Text>
          <ArrowLeft/>
      </TouchableOpacity>

      </View>
    

      <Text style={styles.textDescription}>
      Este projeto tem como objetivo promover reformas em dois prédios localizados no Parque Cidade de Pindamonhangaba, criando o 
      <Text style={styles.textDescriptionBold}> Parque Cultural | Parque da Cidade Pindamonhangaba-SP </Text>
      , promovendo o acesso democrático à cultura e estimulando a criatividade e expressão artística da comunidade. Por meio de palestras, exposições, sessões de cinema e oficinas de arte, o centro buscará fortalecer a identidade cultural local, promover a educação e formação cultural, preservar o patrimônio cultural e integrar a comunidade, além de impulsionar o turismo cultural na região. 
      </Text>

      <Text style={styles.textDescriptionBold}> 
      O projeto já conta com o apoio da Novelis como cota Diamante. {'\n'}
      Buscamos novos patrocinadores para a conclusão do projeto como apresentado.
      </Text>


      <View style={[styles.viewFirstStep,{flexDirection: orientation === 'web' ? 'row' : 'column',
      }]}>
        <View style={{marginTop: 70, alignItems: orientation === 'web' ? 'flex-start' : 'center', width:"100%"}}>
          <Text style={[styles.textTitleGray,{
            fontSize: orientation === 'web' ? 50 : 30,
          }]}>1º Etapa</Text>

          <Text style={[styles.textDescription,{maxWidth: 400}]}>
            Compreende a reforma do galpão 01, que irá receber exposições e oficinas de arte.
          </Text>

       {orientation === 'web' ? imagesWeb(Image1,Image2,Image3) : imagesMobile(Image1,Image2,Image3)}
        </View>
    
      </View>



      <View style={[styles.viewSecondStep]}>
       

       <View style={{marginTop: orientation === 'web' ? 70 : 20, alignItems: orientation === 'web' ? 'flex-start' : 'center', backgroundColor: ''}}>

        <Text style={[styles.textTitleGray,{
          fontSize: orientation === 'web' ? 50 : 30,
        }]}>2º Etapa</Text>

        <Text style={[styles.textDescription,{maxWidth: 400}]}>
        Compreende a reforma do galpão 02, onde teremos o espaço do teatro, cinema e palestras.
        </Text>
        </View>
       {orientation === 'web' ? imagesWeb(Image5,Image6,Image4) : imagesMobile(Image5,Image6,Image4)}


      
      </View>
    </View>
  );
}

